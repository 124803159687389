;(function ($) {
    var $form = $('#create-topic');
    if ($form.get(0) !== undefined) {
        function previewImage() {
            var oFReader = new FileReader();
            oFReader.readAsDataURL($(this).get(0).files[0]);

            oFReader.onload = function (oFREvent) {
                $preview.prop('src', oFREvent.target.result);
                $preview.removeClass('hide');
                $form.find('button[type=submit]').removeClass('disabled');
            };
        }

        var $image = $form.find('input[type=file]'),
            $preview = $form.find('#preview');
        $image.on('change', previewImage);

        $form.find('#choose-image').on('click', function () {
            $image.click();
            return false;
        })
    }

    $('a.edit-post').on('click', function (e) {
        var $post = $(this).closest('.discussion-post');
        var $contents = $post.find('p');

        $contents.replaceWith(
            '<form action="' + $(this).data('route') + '" method="POST"> '+
                '<div class="form-group"> '+
                    '<label for="contents" class="sr-only">Message:</label> '+
                    '<textarea name="contents" id="edit-contents" class="form-control" rows="10">' +
                        $contents.html().replace("<br>", "\n") +
                    '</textarea> '+
                    '<input type="hidden" name="_token" value="' + $(this).data('token') + '">' +
                    '<input type="hidden" name="_method" value="PUT">'+
                '</div>' +
            '</form>'
        );

        var textarea = $post.find('textarea');

        textarea.on('blur', function () {
            $(this).closest('form').submit();
        });

        textarea.focus();

        return false;
    });

    $('a.delete-post').on('click', function (e) {
       $.post($(this).attr('href'), {'_method': 'DELETE'}, function () {
            window.location.reload();
        });

        return false;
    });

})(jQuery);

;
(function ($) {
    function removeTag() {
        var pre_count = $('.tags').children().length;
        $.ajax($(this).prop('href'), {
            method:  'post',
            data:    {_method: 'delete'},
            success: function () {
                $(this).closest('.tag').remove();
            }.bind(this)
        });
       
        if (pre_count <= 4) {
            console.log(pre_count);
            console.log('showing')
              $('.add-tag').show();
        }
        return false;
    }

    function addTag() {
        var $input = $('<input type="text">'), $link = $(this);

        $input.on('blur', ajaxAddTag.bind($input, $link));

        $(this).closest('span').replaceWith($input);

        $input.typeahead({
            hint:      true,
            highlight: true,
            minLength: 1
        }, {
            name:   'tags',
            source: substringMatcher(window.tags)
        }).bind("typeahead:selected", function () {
            $input.blur();
        });

        $input.focus();
       
        return false;
    }

    function ajaxAddTag($link) {
        var $input = $(this);
        var pre_count = $('.tags').children().length;
        $.ajax('/tags', {
            method:  'post',
            data:    {name: $(this).val()},
            success: function (id) {
                var $removeLink = $('<a href="/tags/' + id + '" class="remove-tag">' +
                    '<span class="glyphicon glyphicon-remove"></span></a></span>');
                $removeLink.on('click', removeTag);

                $input.unbind();
                $input.closest('span.twitter-typeahead').replaceWith($('<span class="tag"></span>').append($removeLink));
                $removeLink.closest('span.tag').prepend($input.val());

                $('.tags').append($('<span class="tag plus"></span>').append($link));

                var $addTag = $('.tags').find('.add-tag');
                $addTag.on('click', addTag);

                window.selectedTags.push($(this).val())
                if (window.selectedTags.length == 3) {
                    $addTag.hide()
                }
                else
                {
                    $addTag.show()

                }
                if (pre_count >= 3) {
                    console.log(pre_count);
                    console.log('hiding')
                      $('.add-tag').hide();
                }
            }.bind(this),
            error:   function () {
                $input.unbind();
                $input.closest('span.twitter-typeahead').replaceWith($('<span class="tag"></span>').append($link));
                $link.on('click', addTag);
            }.bind(this)
        });

        $input.prop('disabled', true);
    }

    function substringMatcher(strs) {
        return function findMatches(q, cb) {
            var matches, substrRegex;

            // an array that will be populated with substring matches
            matches = [];

            // regex used to determine if a string contains the substring `q`
            substrRegex = new RegExp(q, 'i');

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            $.each(strs, function (i, str) {
                if (window.selectedTags){
                    if (substrRegex.test(str) && window.selectedTags.indexOf(str) == -1) {
                        matches.push(str);
                    }
                }
                else {
                     if (substrRegex.test(str) ) {
                        str = str.replace('&amp;', '&');
                        matches.push(str);
                    }
                }
               
            });

            cb(matches);
        };
    }

     $('#categoryahead').typeahead({
        hint:      true,
        highlight: true,
        minLength: 1
    }, {
        name:   'tags',
        source: substringMatcher(window.tags)
    }).bind("typeahead:selected", function () {
        $('#categoryahead').blur();
    });

    $('#categoryahead').on('typeahead:change', function () {
        var tags = window.full_tags,
            chosen = $('#categoryahead').val();

        $("#category").val('');
        tags.forEach(function (tag) {

            if (tag.name == chosen) {
                $("#category").val(tag.id);
            }
        });
    });

    
    var $tags = $('.tag');
    $tags.find('.remove-tag').on('click', removeTag);
    $tags.find('.add-tag').on('click', addTag);

})(jQuery);

;
(function ($) {

    var $profile = $('#profile').find('.owner');
    if ($profile.get(0) !== undefined) {
        function replaceWithTextArea($a, rows) {
            var $textarea = $('<textarea style="min-height: 300px;" maxlength="1000" class="form-control"></textarea>');
            $textarea.data('link-update', $(this).data('link-update'));
            $textarea.val($.trim($(this).text()));
            $textarea.prop('rows', rows);

            $textarea.on('blur', function () {
                $textarea.prop('disabled', true);

                $.ajax($(this).data('link-update'), {
                    method:  'post',
                    data:    {value: $(this).val()},
                    success: function () {
                        var $p = $('<p></p>').text($(this).val());
                        $p.on('click', replaceWithTextArea);
                        $p.data('link-update', $(this).data('link-update'));

                        if ($(this).val() != '' && $(this).closest('.main-side').get(0) !== undefined) {
                            $('#no-overview').hide();
                        }

                        $(this).replaceWith($p);

                    }.bind(this)
                });
            });

            $(this).replaceWith($textarea);
            $textarea.focus();
            return false;
        }

        function replaceWithInput() {
            var $this        = $(this);
            var $input       = $('<input type="text" placeholder="Address" id="google-autocomplete" class="form-control">');
            var autocomplete = new window.google.maps.places.Autocomplete($input.get(0));

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                $input.prop('disabled', true);

                $.ajax('/me/update-location', {
                    method: 'post',
                    data: {value: $input.val()},
                    success: function () {
                        var $container = $('#actual-location');

                        $container.html($input.val());

                        $input.replaceWith($this);
                        $this.on('click', replaceWithInput);
                    },
                    error: function () {
                        $input.prop('disabled', false);
                    }
                })
            });

            $(this).replaceWith($input);
            $input.focus();

            return false;
        }

        var $text, $link;

        $text = $profile.find('.overview p');
        $link = $profile.find('.overview a, .overview button');

        $text.on('click', replaceWithTextArea.bind($text, $link, 7));
        $(document).on('click', '.overview a', function () {
            $('.overview p').trigger('click');
        });

        $text = $profile.find('.headline p');
        $link = $profile.find('.headline a');

        $text.on('click', replaceWithTextArea.bind($text, $link, 2));
        $(document).on('click', '.headline a', function() {
            $('.headline p').trigger('click');
        });

        $text = $profile.find('.telephone p.tel_edit');
        $link = $profile.find('.telephone a');

        $text.on('click', replaceWithTextArea.bind($text, $link, 1));
        $(document).on('click', '.telephone a', function() {
            $('.telephone p').trigger('click');
        });

        $text = $profile.find('.myurl p');
        $link = $profile.find('.myurl a');

        $text.on('click', replaceWithTextArea.bind($text, $link, 1));
        $(document).on('click', '.myurl a', function() {
            $('.myurl p').trigger('click');
        });

        $profile.find('#change-location').on('click', replaceWithInput);

        $(document).on('click', '#no-overview a', function() {
            console.log('test');
            $('#no-overview').hide();
        });
    }

})(jQuery);

;
(function ($) {
    function substringMatcher(strs) {
        return function findMatches(q, cb) {
            var matches, substrRegex;

            // an array that will be populated with substring matches
            matches = [];

            // regex used to determine if a string contains the substring `q`
            substrRegex = new RegExp(q, 'i');

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            $.each(strs, function (i, str) {
                
                if(str.company && str.company.name != ''){
                    if (substrRegex.test(str.company.name)) {
                    matches.push(str.company.name);
                    } 
                }
               
            });

            cb(matches);
        };
    }

    var $field4 = $('#request-a-review').find('#receiver_name');
    $field4.typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    }, {
        name: 'review-request-receivers',
        source: substringMatcher($field4.data('users'))
    });

    $field4.on('typeahead:change', function () {
        var users = $field4.data('users'),
            chosen = $field4.val();

        $("#receiver_id").val('');
        users.forEach(function (user) {
            if (user.company.name == chosen) {
                $("#receiver_id").val(user.id);
            }
        });
    });

    $field4.closest('form').on('submit', function () {
        if ($("#receiver_id").val() == '') {
            return false;
        }
    })
    var $field = $('#send-a-message').find('#receiver_name');
    $field.typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    }, {
        name: 'review-request-receivers',
        source: substringMatcher($field.data('users'))
    });

    $field.on('typeahead:change', function () {
        var users = $('#send-a-message').find('#receiver_name').data('users'),
            chosen = $field.val();

        $("#receiver_id").val('');
        users.forEach(function (user) {
            if (user.company.name == chosen) {
                $("#receiver_id").val(user.id);
            }
        });
    });

    $field.closest('form').on('submit', function () {
        if ($("#receiver_id").val() == '') {
            return false;
        }
       
    });

    /** @type {company-slug} [Allows for custom admin reviews] */
    var $fieldd = $('.form-group').find('#company_nome');
    $fieldd.typeahead({
        hint: true,
        highlight: true,
        minLength: 1
    }, {
        name: 'review-request-receivers',
        source: substringMatcher($fieldd.data('users'))
    });
    $fieldd.on('typeahead:change', function () {
        var users = $('.form-group').find('#company_name').data('users'),
            chosen = $fieldd.val(), companies = $('.form-group').find('#company_name').data('companies');

        $("#user_id").val('');
        users.forEach(function (user) {
            if (user.company.name == chosen) {
                $("#user_id").val(user.id);
            }
        });
    });

    if(jQuery("#byAnth").is(":checked")){
        $fieldd.closest('form').on('submit', function () {
            if ($("#user_id").val() == '') {
                return false;
            }
        });
    }
    /** END */


      $('.reply_btn').on('click',function(){
        var company_id = $(this).attr('data-reply-id');
        var company_name = $(this).attr('data-reply-name');
        var subject = 'Re: '+ $(this).attr('data-reply-sub');
        $('#message_subject').val(subject);
        $('#message_subject').attr('value',subject);
        $('#send-a-message').find('#receiver_name').typeahead('val',company_name).trigger('change');
        $('#send-a-message').find('#receiver_id').val(company_id);
        $('#send-a-message').find('#receiver_name').attr('disabled','disabled');
    });
    $(' #company-links .contact_btn').on('click',function(){
        $('#send-a-message').find('#receiver_id').val($(this).attr('data-reply-id'));
        $('#send-a-message').find('#receiver_name').attr('disabled','disabled');
        $('#send-a-message').find('#receiver_name').typeahead('val',$(this).attr('data-reply-name')).trigger('change');

    });
        $('#new_message').on('click',function(){
           $('#send-a-message').find('#receiver_name').prop('disabled', false);
        });
        console.log("test");
              console.log("test2");
            var substringMatcher = function (strs) {
                  console.log("test3");
                return function findMatches(q, cb) {
                    var matches, substrRegex;

                    // an array that will be populated with substring matches
                    matches = [];

                    // regex used to determine if a string contains the substring `q`
                    substrRegex = new RegExp(q, 'i');

                    // iterate through the pool of strings and for any string that
                    // contains the substring `q`, add it to the `matches` array
                    $.each(strs, function (i, str) {
                        if (substrRegex.test(str[0])) {
                            matches.push(str[0]);
                        }
                    });

                    cb(matches);
                };
            };


            var $modal2 = $('#edit-a-service');
            var $nameInput2 = $modal2.find('input#edit_name');
            var $textarea2 = $modal2.find('textarea#description');
            if (typeof services !== 'undefined') {
                $nameInput2.typeahead({
                    hint:      true,
                    highlight: true,
                    minLength: 1,
                    name: 'service_edit'
                }, {
                    name:   'services',
                    source: substringMatcher(services)
                }).bind("typeahead:selected", function () {
                    $nameInput2.blur();
                });
            }
             $('.edit_service').click(function(){
               
                $('#service_id').val($(this).attr('data-service'));
               
                $nameInput2.typeahead('val', $(this).attr('data-name'))
               
                $('#edit_description').val($(this).attr('data-desc'));
                $('#priority').val($(this).attr('data-priority'));
                $('#old_priority').val($(this).attr('data-priority'));
               
            });
           
})(jQuery);

;
(function ($) {

    if (window.location.pathname.indexOf('/register') === 0) {
        var form = $('#registration').find('form');

        form.card({
            container: '.card-wrapper',

            formSelectors: {
                numberInput: 'input#card_number',
                expiryInput: 'input#card_expiration_month, input#card_expiration_year',
                cvcInput:    'input#card_cvc',
                nameInput:   'input#first_name, input#last_name'
            },

            width:      300,
            formatting: true,

            values: {
                number: '•••• •••• •••• ••••',
                name:   '',
                expiry: '••/••',
                cvc:    '•••'
            }
        });

        form.submit(function () {
            var $this   = $(this);
            if ($this.find('#pay_offline').prop('checked')) {
                $this.get(0).submit();
            }
            var $submit = $this.find('input[type=submit]');
            var fields  = {
                number:    $this.find('input#card_number'),
                cvc:       $this.find('input#card_cvc'),
                exp_month: $this.find('input#card_expiration_month'),
                exp_year:  $this.find('input#card_expiration_year')
            };
            var values  = {
                number:    $this.find('input#card_number').val(),
                cvc:       $this.find('input#card_cvc').val(),
                exp_month: $this.find('input#card_expiration_month').val(),
                exp_year:  $this.find('input#card_expiration_year').val()
            };

            $submit.prop('disabled', true);

            Stripe.card.createToken(values, stripeResponseHandler);

            return false;

            function stripeResponseHandler(status, response) {
                console.log(response);
                for (var field in fields) {
                    fields[field].parents(':eq(1)').removeClass('has-error');
                }

                if (response.error) {
                    $this.find('.payment-errors').text(response.error.message);

                    fields[response.error.param].parents(':eq(1)').addClass('has-error');

                    $submit.prop('disabled', false);
                } else {
                    var token = response.id;
                    $this.find('#stripe_token').val(token);
                    $this.get(0).submit();
                }
            }
        });
    }
})(jQuery);

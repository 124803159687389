;
(function ($) {
    var $preview = $('#upload-preview');
    var $modal = $('#upload-file');

    function previewImage() {
        var oFReader = new FileReader();
        oFReader.readAsDataURL($(this).get(0).files[0]);

        oFReader.onload = function (oFREvent) {
            $preview.prop('src', oFREvent.target.result);
            $preview.show();
        };
    }

    $preview.hide();

    $modal.find('form input[type=file]').on('change', previewImage);
    $modal.on('show.bs.modal', function (e) {
        var $button = $(e.relatedTarget),
            url     = $button.data('to');

        $modal.find('form').prop('action', url);
    });
})(jQuery);

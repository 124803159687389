;(function ($) {
    $('.show-service').on('click', function () {
        var $service = $(this).parent().children('.service');
        if($service.is(':visible')) {
            $service.hide();
        } else {
            $service.show();
        }
    });
    $('.show-job').on('click', function () {
        var $job = $(this).parent().children('.job');
        if($job.is(':visible')) {
            $job.hide();
        } else {
            $job.show();
        }
    });

    $(document).on('keyup', '.single-tag', function() {
        $this = $(this);
        ctag = $this.attr('tag');

        tagClass = '.' + ctag;

        $selectTag = $this.parents().find(tagClass);
        $selectTag.html('');
        
        html = '';

        window.tags.forEach(function (tag) {
            if(tag.toLowerCase().indexOf($this.val().toLowerCase()) != -1) {
                if (tag)
                $selectTag.show();
                html += '<option value="' + tag + '">' + tag + '</option>';
            } else {

            }
        });

        $selectTag.append(html);
    });

    $(document).on('click', '.selecttag', function() {
        console.log('click');
        $this = $(this);
        ctag = $this.attr('tag');

        $tag = $this.parents().find('#' + ctag);
        $tag.val($this.val());

        $this.hide();
    });

})(jQuery);

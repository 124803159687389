;
(function ($) {
    $.fn.isBefore = function (sel) {
        return this.nextAll().filter(sel).length !== 0;
    };

    $.fn.isAfter = function (sel) {
        return this.prevAll().filter(sel).length !== 0;
    };

    if (!Array.prototype.last) {
        Array.prototype.last = function () {
            return this[this.length - 1];
        };
    }

    $('a[href*=#]:not([href=#])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target     = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 500);
                return false;
            }
        }
    });
})(jQuery);

;(function ($) {
    if (window.location.pathname === '/') {
        var detector           = document.createElement("detect");
        detector.style.display = "flex";

        if (detector.style.display !== "flex") {
            $('.row-eq-height').find('> div').matchHeight();
        }
    }
})(jQuery);

;
(function ($) {


    $( "#start_date" ).datepicker(
    { dateFormat: 'yy-mm-dd',
      minDate: new Date()
    }
   );
    $( "#expires" ).datepicker(
    { dateFormat: 'yy-mm-dd',
      minDate: new Date()
    }
   );
    var $jobForm = $('#create-job');
    if ($jobForm.get(0) !== undefined) {
        var countIndustries = 1;

        function PlaceChanged() {
            var place      = autocomplete.getPlace(),
                components = place.address_components,
                location   = [],
                ignore     = [
                    'United Kingdom'
                ];

            for (var i in components) {
                if (components[i].long_name !== undefined &&
                    location.indexOf(components[i].long_name) === -1 &&
                    ignore.indexOf(components[i].long_name) === -1) {
                    location.push(components[i].long_name);
                }
            }

            console.log(location);

            $input.val(location.join(', '))
        }

        function addIndustry() {
            var $link = $(this), $container = $(this).closest('div');

            $link.replaceWith($('#industries-first-field').find('> div').clone());
            $container.find('select').prop('id', ++countIndustries);
            $container.find('label').prop('for', countIndustries);
            if (countIndustries == 3) {
                 return false;
            }
            var $div = $('<div class="col-xs-12 col-sm-4"></div>');
            $container.after($div);

            $div.append($link);
            $link.on('click', addIndustry);

            return false;
        }

        var $jobs = $('#jobs');
        $jobs.find('form select#temporary').on('change', function () {
            $('#jobs').find('form input#end_date').closest('.col-xs-6').toggleClass('hide');
        });

        $jobs.find('form a#add-industry').on('click', addIndustry);

        $jobForm.find('form #save-draft').on('click', function () {
            var $form = $(this).closest('form');
            $form.append('<input type="hidden" name="draft" value="yes">');
            $form.submit();
        });

        var $input       = $('input#location'),
            options      = {
                componentRestrictions: {country: "uk"}
            },
            autocomplete = new window.google.maps.places.Autocomplete($input.get(0), options);

        google.maps.event.addListener(autocomplete, 'place_changed', PlaceChanged);
    }

    var $applicationForm = $('.apply-for-a-job');
    if ($applicationForm.get(0) !== undefined) {
        $applicationForm.find('#add-attachment').on('click', function () {
            var $newFileField = $('<li><a href="#" class="remove-attachment">&times;</a><input type="file" name="attachments[]"></li>');

            $(this).closest('li').before($newFileField);
            $newFileField.find('.remove-attachment').on('click', function () {
                $(this).closest('li').replaceWith('');

                return false;
            });

            return false;
        })
    }

    var $modal = $('#review-application');
    $modal.on('show.bs.modal', function (e) {
        var $button       = $(e.relatedTarget),
            message       = $button.data('message'),
            attachments   = $button.data('attachments'),
            applicationId = $button.data('id');
        var $attachments = $modal.find('#application-attachments');

        $modal.find('#application-message').html(message);
        $modal.find('#application_id-field').val(applicationId);
        $attachments.html('');

        for (var i in attachments) {
            if(i != 'last'){
                var $attachment = $('<li><a class="'+i+'" target="_blank" href="'+ attachments[i].link +'">'+ attachments[i].name +'</a></li>');
                $attachments.append($attachment);
            }
        }
    });

    var $buyJobsForm = $('#buy-jobs');
    if ($buyJobsForm.get(0) !== undefined) {
        $buyJobsForm.find('button[data-price]').on('click', function() {
            $buyJobsForm.find('#price > span').html($(this).data('price'));
            $buyJobsForm.find('#number-field').val($(this).data('number'));

            return false;
        });

        $buyJobsForm.find('#showCardDetails').click(function () {
            $buyJobsForm.find('#card_details').show();
            $(this).hide();
        });

        $buyJobsForm.card({
            container: '.card-wrapper',

            formSelectors: {
                numberInput: 'input#card_number',
                expiryInput: 'input#card_expiration_month, input#card_expiration_year',
                cvcInput:    'input#card_cvc',
                nameInput:   'input#first_name, input#last_name'
            },

            width:      300,
            formatting: true,

            values: {
                number: '•••• •••• •••• ••••',
                name:   '',
                expiry: '••/••',
                cvc:    '•••'
            }
        });

        $buyJobsForm.submit(function () {
            var $this   = $(this);
            var $submit = $this.find('input[type=submit]');
            var fields  = {
                number:    $this.find('input#card_number'),
                cvc:       $this.find('input#card_cvc'),
                exp_month: $this.find('input#card_expiration_month'),
                exp_year:  $this.find('input#card_expiration_year')
            };
            var values  = {
                number:    $this.find('input#card_number').val(),
                cvc:       $this.find('input#card_cvc').val(),
                exp_month: $this.find('input#card_expiration_month').val(),
                exp_year:  $this.find('input#card_expiration_year').val()
            };

            if (fields.number.length() == 0) return true;

            $submit.prop('disabled', true);

            Stripe.card.createToken(values, stripeResponseHandler);

            return false;

            function stripeResponseHandler(status, response) {
                for (var field in fields) {
                    fields[field].parents(':eq(1)').removeClass('has-error');
                }

                if (response.error) {
                    $this.find('.payment-errors').text(response.error.message);

                    fields[response.error.param].parents(':eq(1)').addClass('has-error');

                    $submit.prop('disabled', false);
                } else {
                    var token = response.id;

                    $this.append($('<input type="hidden" name="stripe_token">').val(token));
                    $this.get(0).submit();
                }
            }
        });
    }
    $(document).ready(function () {
        if (window.availableJobsForTypeahead !== undefined) {
            var $jobs = $('#jobs');
            var $jobs_input  = $jobs.find('.search-jobs').find('#title');
            if($jobs_input.length <= 0){
                var $jobs_input  = $jobs.find('.top-guest').find('#title');
            }
            function substringMatcher(strs) {
                return function findMatches(q, cb) {
                    var matches, substrRegex;

                    // an array that will be populated with substring matches
                    matches = [];

                    // regex used to determine if a string contains the substring `q`
                    substrRegex = new RegExp(q, 'i');

                    // iterate through the pool of strings and for any string that
                    // contains the substring `q`, add it to the `matches` array
                    $.each(strs, function (i, str) {
                        if (substrRegex.test(str)) {
                            matches.push(str);
                        }
                    });

                    cb(matches);
                };
            }

            $jobs_input.typeahead({
                hint:      true,
                highlight: true,
                minLength: 1
            }, {
                name:   'Jobs',
                source: substringMatcher(availableJobsForTypeahead)
            });

            $jobs.find('.company').on('mouseover', function () {
                var $img = $(this).find('.location > img');
                $img.prop('src', '/img/profiles/location-hover.png')
            }).on('mouseout', function () {
                var $img = $(this).find('.location > img');
                $img.prop('src', '/img/profiles/location.png')
            });
         
        }
         if (window.availableJobLocationsForTypeahead !== undefined) {
            var $jobs = $('#jobs');
            var $jobs_input  = $jobs.find('.search-jobs').find('#location');
            if($jobs_input.length <= 0){
                var $jobs_input  = $jobs.find('.top-guest').find('#location');
            }
            function substringMatcher(strs) {
                return function findMatches(q, cb) {
                    var matches, substrRegex;

                    // an array that will be populated with substring matches
                    matches = [];

                    // regex used to determine if a string contains the substring `q`
                    substrRegex = new RegExp(q, 'i');

                    // iterate through the pool of strings and for any string that
                    // contains the substring `q`, add it to the `matches` array
                    $.each(strs, function (i, str) {
                        if (substrRegex.test(str)) {
                            matches.push(str);
                        }
                    });

                    cb(matches);
                };
            }

            $jobs_input.typeahead({
                hint:      true,
                highlight: true,
                minLength: 1
            }, {
                name:   'Jobs',
                source: substringMatcher(availableJobLocationsForTypeahead)
            });

            $jobs.find('.company').on('mouseover', function () {
                var $img = $(this).find('.location > img');
                $img.prop('src', '/img/profiles/location-hover.png')
            }).on('mouseout', function () {
                var $img = $(this).find('.location > img');
                $img.prop('src', '/img/profiles/location.png')
            });
         
        }
    });
})(jQuery);
